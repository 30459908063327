.page-content {
	padding: 50px 0;
}

.contact-our-team {
	padding: 15px 0 30px;
    text-align: center;

	h4 {
		font-size: 24px;
		color: #747577;
		margin: 0;

		@include respond-over($screen-xs) {
			font-size: 35px;
		}

	}

	p {
		color: #747577;
		font-size: 18px;
	}

	a {
		color: #381d54;
	}

}
