.hero {

	padding: 50px 0;
	position: relative;
	background-size: cover;
	background-position: center center;

	@include respond-over($screen-xs) {
		padding: 100px 0;
	}

	&-overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0.5;
		z-index: 10;
	}


	&-text {
		font-size: 24px;
		font-weight: 100;
		color: #ffffff;
		z-index: 20;
		position: relative;
	}

	&-title {
		color: #ffffff;
		font-size: 30px;
		z-index: 20;
		position: relative;

		@include respond-over($screen-xs) {
			font-size: 70px;
		}

	}

}