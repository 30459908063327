.homepage-blocks {
	max-width: 2000px;
	margin: 0 auto;

	@include respond-over($screen-md) {
		border-right: 5px solid #ffffff;
		border-left: 5px solid #ffffff;
        display: flex;
	}

}

.homepage-block {

	position: relative;
	float: left;
	width: 100%;
	padding: 0 10px;
	border: 0;
	position: relative;
	z-index: 500;

	p {
		color: #ffffff;
	}

	&:hover {
		text-decoration: none;
		filter: brightness(110%);
	}

	&.mod-disabled-links {
		&:hover {
			filter: brightness(100%);
		}
	}

	&:focus {
		text-decoration: none;
	}

	@include respond-over($screen-sm) {
		padding: 0 19px;
	}

	@include respond-over($screen-md) {
		border-left: 5px solid #ffffff;
		width: 20%;
		padding: 0 25px;
	}

	&.mod-small {
		height: 300px;

		&.mod-bg {
			height: 150px;

			@include respond-over($screen-xs) {
				height: 300px;
			}

		}

	}

	&.mod-large {
		height: 300px;

		@include respond-over($screen-md) {
			height: 500px;
		}

		&.mod-bg {
			height: 300px;

			@include respond-over($screen-sm) {
				height: 500px;
			}
		}

        &.mod-disabled-links {
            @include respond-under($screen-lg) {
                height: auto;
                padding-bottom: 3rem;
            }
        }
	}

	&.mod-bg {
		width: 100%;
		background-size: cover;
		background-position: center center;
		position: relative;
		border-left:0;

		.mod-bg-overlay {
			position: absolute;
			z-index: 10;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background-color: rgba(0,0,0,0.4);
		}

		&:hover {
			filter: none;
		}

		@include respond-over($screen-md) {
			width: 40%;
		}

		p {
			margin-top: 40px;
			padding-left: 15px;
			color: #ffffff;
			font-size: 18px;
			font-weight: 100;
			margin-bottom: 0;
			z-index: 20;
			position: relative;

			@include respond-over($screen-xs) {
				font-size: 24px;
				padding-left: 25px;
			}

		}

		.homepage-block-title {
			padding-left: 10px;
			border-left: 4px solid;
			font-size: 34px;
			margin-top: 30px;
			padding-top: 0;
			z-index: 20;
			position: relative;

			@include respond-over($screen-xs) {
				font-size: 40px;
				padding-left: 20px;
			}

			@include respond-over($screen-lg) {
				font-size: 50px;
			}

			@include respond-over($screen-xl) {
				font-size: 72px;
			}

		}

		&.mod-small {

			p {
				display: none;
			}

			.homepage-block-title {
				position: relative;
				margin-top: -35px;
				top: 50%;

				@include respond-over($screen-xs) {
					margin-top: -79px;
					top: 50%;
				}

			}

		}

	}

	&.mod-purple {
		background: #381d54;
	}

	&.mod-pink {
		background: #d32d83;
	}

	&.mod-grey {
		background: #8b8b8b;
	}

	&-title {
		color: #ffffff;
		padding-top: 40px;
		font-size: 30px;
		margin-top: 0;

		@include respond-over($screen-md) {
			font-size: 24px;
		}

		@include respond-over($screen-lg) {
			font-size: 26px;
		}

		@include respond-over($screen-xl) {
			font-size: 30px;
		}

	}

	&-text {
		font-size: 24px;
		font-weight: 100;

		@include respond-over($screen-md) {
			font-size: 20px;
		}

		@include respond-over($screen-lg) {
			font-size: 24px;
		}

		p {
			margin: 0;
			color: #ffffff;
		}

	}

	&-link {
		color: #ffffff;
		font-size: 22px;
		position: absolute;
		bottom: 40px;

		.fi {
			font-size: 22px;
		}

		&:hover {
			color: #ffffff;
			text-decoration: none;
		}

		@include respond-over($screen-md) {
			font-size: 18px;
		}

		@include respond-over($screen-lg) {
			font-size: 22px;
		}

	}

	&-expanded {
		height: 0;
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		padding: 80px 25px 40px 25px;
		z-index: 10;
		color: #ffffff;
		font-size: 15px;
		min-height: 450px;

		ul {
			padding-left: 25px;

			li {
				padding-bottom: 10px;
				list-style: none;

				&:before {
					content: '\f103';
					font-family: "Flaticon";
					position: absolute;
					font-size: 12px;
					left: 25px;
				}

			}

		}

		&.open {
			display: block;
			height: auto;

		}

		.flaticon-close {
			color: #ffffff;
			margin-top: 40px;
			display: block;
			font-size: 22px;
		}

	}

	&.js-expand-block-2 {
		z-index: 600;
	}

	&.js-expand-block-3 {
		z-index: 500;
	}

	&.js-expand-block-4 {
		z-index: 400;
	}

}
