.header {

	&-top {
		background: #f3f3f3;

		&-inner {
			float: none;
			text-align: center;

			@include respond-over($screen-sm) {
				float: right;
				text-align: left;
			}

		}

		&-button {
			background: #80187e;
			line-height: 60px;
			height: 60px;
			display: inline-block;
			font-size: 15px;
			width: 50%;
			text-align: center;

            &__cv {
                background: #008B8B;
            }

            &__franchise {
                background: #3a1a54;
            }

			@include respond-over($screen-xs) {
				width: auto;
				font-size: 16px;
			}

            @include respond-over($screen-md) {
                min-width: 200px;
            }

			&.dark-background {
				background: #381d54;
			}

			a {
				color: #ffffff;
				display: block;
				padding: 0px;

				@include respond-over($screen-md) {
					padding: 0 35px;
				}

				&:hover {
					color: #ffffff;
					text-decoration: none;
				}

			}

			.fi {
				padding-left: 10px;
			}

		}

		&-contact {
			display: inline-block;
			text-align: center;
		}

		&-text {
			display: inline-block;
			padding-right: 10px;
			border-right: 1px solid #b1aeb0;
			line-height: 14px;
			font-size: 13px;
			margin: 18px 0px;

			a {
				color: #391f54;
			}

			@include respond-over($screen-xs) {
				padding: 0 25px;
				border-left: none;
				font-size: 16px;
			}

			@include respond-over($screen-sm) {
				padding: 0 30px;
			}

			&:last-child {
				border: 0;

				@include respond-under($screen-xs) {
					padding-left: 10px;
				}

				@include respond-over($screen-sm) {
					padding: 0 50px;
					border-right: 1px solid #b1aeb0;
				}

			}

		}

	}

	&-logo {
		margin-top: 28px;

		@include respond-over($screen-xs) {
			padding: 0;
		}

	}

	.mobile-logo {
		float: left;
		margin-top: 15px;
	}

	&-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
		height: 100px;
		background: #ffffff;
        position: relative;

        .header-logo {
            margin: 0;
            padding: 0;
        }

        .header-bottom-container {
            display: flex;
            align-items: center;
            @include respond-under($screen-md) {
                width: 100%;
            }

            @include respond-over($screen-md) {
                .navigation {
                    margin-left: auto;
                    margin-right: auto;
                    .navigation-list {
                        display: flex;
                        align-items: center;
                    }
                    .navigation-item-link {
                        margin-top: 0;
                        padding: 0 1rem;
                    }
                }
            }
        }

        .footer-logos {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .partner-logo-link {
                display: inline-block;
                margin-left: 40px;
                span {
                    display: block;
                }
            }

            .apscouk-logo {
                width: 130px;
            }
        }

        .mobile-nav-trigger {
            top: 25px;
            right: 20px;
            position: absolute;
        }


        @include respond-over($screen-md) {
            height: 150px;
            .navigation {
                margin-left: auto;
                .navigation-item-link {
                    margin-top: 0;
                }
            }
        }
	}

}

.navigation {

	float: right;

	&-list {
		padding: 0;
		margin-bottom: 0;
	}

	&-item {

		position: relative;
		display: inline-block;

		&.has-children {
			&:hover {
				.navigation-sub {
					display: block;
					opacity: 1;
				}
			}
		}

		&-link {
			position: relative;
			color: #646668;
			display: block;
			font-weight: 100;
			font-size: 20px;
			padding: 15px 10px 0 15px;
			line-height: 20px;
			margin-top: 55px;
			border-left: 1px solid #dcdcdc;

			@include respond-over($screen-lg) {
				padding: 15px 30px 0 15px;
			}

			.fi {
				position: absolute;
				font-size: 10px;
				top: 18px;
				right: 25px;
			}

			&:hover {
				color: #391855;
				text-decoration: none;

				&:before {
					content: '';
					position: absolute;
					height: 1px;
					left: 10px;
					bottom: -10px;
					right: 20px;
					background-color: #391855;
				}

			}

			&:focus {
				outline: none;
				text-decoration: none;
			}

		}

		&.has-children {
			.navigation-item-link {

				padding: 15px 10px 0 15px;

				@include respond-over($screen-lg) {
					padding: 15px 40px 0 15px;
				}
			}
		}

		&:last-child {
			.navigation-item-link {
				padding-right: 0;

				&:before {
					right: -10px;
				}

			}
		}

	}

	&-sub {
		position: absolute;
		left: -50%;
		margin-left: -20px;
		width: 350px;
		padding: 0;
		z-index: 800;
		padding: 10px 40px 20px 40px;
		display: none;
		opacity: 0;

		&-inner {
			background-color: #ffffff;
			padding: 20px;
			border: 1px solid #e3e3e3;
		}

		&-list {
			list-style: none;
			padding: 0;
		}

		&-item {
			list-style: none;
			font-size: 20px;

			&-link {
				color: #646668;
				font-weight: 100;
				padding-bottom: 5px;
				margin-bottom: 10px;
				display: inline-block;
				border-bottom: 1px solid transparent;

				&:hover {
					border-bottom: 1px solid #391855;
					text-decoration: none;
					color: #391855;
				}

			}

		}

		&:before {
			content: '';
			width: 0px;
			height: 0;
			border-style: solid;
			border-width: 0px 10px 10px 10px;
			border-color: transparent transparent #e3e3e3 transparent;
			left: 50%;
			position: relative;
			top: -8px;
			margin-left: -15px;
		}

	}

}

.social-icons {
	display: inline-block;
	padding-left: 30px;
	top: 2px;
	position: relative;

	@include respond-over($screen-xs) {
		padding: 0 0 0 50px;
	}

	@include respond-over($screen-md) {
		padding: 0 50px;
	}

	.social-icon {
		padding-right: 10px;
		font-size: 24px;

		&:last-child {
			padding-right: 0;
		}

		&.facebook {
			color: #3c5898;
		}

		&.twitter {
			color: #5aabee;
		}

		&.linkedin {
			color: #1a75a8;
		}

	}

}

.mobile-nav-trigger {
	float: right;
	font-size: 32px;
	position: relative;
	top: 27px;
	right: 0px;
	color: #381d54;

	@include respond-over($screen-md) {
		font-size: 50px;
		top: 39px;
	}

	span {
		color: #381d54;
	}

}

.mobile-navigation {
	position: fixed;
	z-index: 500;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	background: #ffffff;
	overflow-y: auto;
	z-index: 1000;

	&-list {
		padding: 0;
	}

	&-item {
		font-size: 14px;
		border-bottom: 1px solid #d9d9d9;
		color: #30373b;

		@include respond-over($screen-xs) {
			font-size: 24px;
		}

		&:first-child {
			padding: 14px 25px;
			font-weight: bold;
			color: #30373b;
		}

		&-link {
			color: #646668;
			padding: 14px 25px;
			display: block;

			&:hover {
				color: #381d54;
			}

			.flaticon-down-arrow {
				position: absolute;
				right: 20px;
				color: #646668;
				font-size: 12px;
			}

		}

	}

	&-close {
		position: absolute;
	    right: 20px;
	    top: 8px;
	    font-size: 22px;
	    color: #381d54;

	    @include respond-over($screen-xs) {
	    	font-size: 28px;
	    }

	}

	&-button {
		width: 100%;
		text-align: left;
		padding: 0 25px;
	}

	.social-icons {
		margin-top: 10px;
		padding-left: 25px;

		.social-icon {
			font-size: 30px;
		}

	}

	&-sub {
		background-color: #e9e9e9;
		padding: 0;
		list-style: none;

		&-item {
			border-top: 1px solid #d9d9d9;

			&-link {
				color: #30373b;
				padding: 15px 35px;
				display: block;
			}

		}

	}

}

